@media (min-width: 1024px) {
  #root {
    nav {
      main#views {
        #workshop {
          #AllWorks {
            float: none;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
  }
}
