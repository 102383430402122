* {
  box-sizing: border-box;
  padding: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

html,
body {
  font-family: "Source Sans Pro", sans-serif;
  height: 100%;
  background: url("../assets/images/tileWhite.png");
  min-height: 100%;
}

h1 {
  font-size: $h1;
  margin-bottom: $h1-margin;
  margin-top: $h-margin;
  margin-left: $h-margin;
  margin-right: $h-margin;
  font-weight: 700;
}

h2 {
  font-size: $h2;
  margin-bottom: $h2-margin;
  font-weight: 700;
  margin-top: $h-margin;
  margin-left: $h-margin;
  margin-right: $h-margin;
}

h3 {
  margin-bottom: $h3-margin;
  font-weight: 700;
  margin-top: $h-margin;
  margin-left: $h-margin;
  margin-right: $h-margin;
}

h4 {
  font-size: $h4;
  margin-bottom: $h4-margin;
  font-weight: 700;
  margin-top: $h-margin;
  margin-left: $h-margin;
  margin-right: $h-margin;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 5px;
    //padding: 5px;
  }

  header {
    position: relative;
    display: inline-block;
    height: 40px;
    width: 100%;
    padding: 5px;
    //background-color: $ricardoworks-black;
    //background-image: url("./assets/images/blackTrans.png");
    //background-image: url("./assets/images/tileGrey.png");
    //margin-bottom: 10px;
    // border: 3px solid $ricardoworks-old-green;
    &:hover {
      #logoAlt {
        display: inline;
      }
    }
    &:focus {
      #logoAlt {
        display: inline;
      }
    }
    img {
      width: 100%;
      height: 100%;
      &:hover {
        cursor: pointer;
      }
    }

    #logoAlt {
      display: none;
      position: absolute;
      left: 0;
      z-index: 99;
      height: 30px;
    }
  }

  footer {
    color: $ricardoworks-white;
    width: 100%;
    background-image: url("../assets/images/tileGrey.png");
    flex-shrink: 0;
    #socialMedia {
      display: flex;
      margin-top: 13px;
      justify-content: space-around;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      text-align: center;
      li {
        width: 50px;
        height: 50px;
        display: block;
        float: left;
        margin-bottom: 10px;
        a {
          display: block;
          border: $border-inactive;
          background-color: $ricardoworks-white;
          padding: 5px;
          border-radius: 5px;
          transition-property: background-color;
          transition-duration: 0.5s;
          img {
          }
          &:hover {
            border: $border-active;
            background-color: $ricardoworks-lightgrey;
            color: $ricardoworks-green;
            cursor: pointer;
          }
          &:focus {
            border: 3px solid $ricardoworks-green;
            background-color: $ricardoworks-lightgrey;
            color: $ricardoworks-green;
          }
        }
      }
    }
    p {
      height: 35px;
      overflow: scroll;
      background-color: black;
      padding: 5px;
    }
  }
  nav {
    margin-bottom: 10px;
  }

  main#views {
    margin-top: 110px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    flex-grow: 1;
    h2 {
      text-align: center;
    }

    #workshop {
      #AllWorks {
        overflow: scroll;
        background: url("../assets/images/tileGrey.png");
        .piece {
          height: 250px;
          width: 250px;
          border-radius: 20px;
          margin-bottom: 10px;
          margin-right: auto;
          margin-left: auto;
          position: relative;
          background-color: #000;

          .pieceCover {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background-image: url("../assets/images/tileBlack.png");
            border-radius: 20px;
            border: $border-inactive;
            animation-name: border-pulse;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            img {
              margin-top: 35px;
              width: 80%;
              margin-left: auto;
              display: block;
              margin-right: auto;
            }
            &:hover {
              border: 3px solid $ricardoworks-green;
              cursor: pointer;
            }
            &:focus {
              border: 3px solid $ricardoworks-green;
            }
          }
        }
      }
    }

    #home {
      #recentProjects {
        margin-left: auto;
        margin-right: auto;
        a {
          display: block;
          padding: 10px;
          border-radius: 3px;
        }
      }
      #postBox {
        min-height: 100px;

        .blogPost {
          margin-bottom: 10px;
          img,
          div {
            margin-bottom: 10px;
          }

          img {
            display: block;
            height: 200px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;
          }

          .blogDate {
            margin-bottom: 10px;
            display: block;
          }

          button {
            margin-left: 5px;
            // border: 3px solid #ff6200;
            border: 3px solid $border-inactive;
            padding: 3px;
            background-color: #fff;
            border-radius: 50px;
            padding: 5px;
          }
          button:first {
            margin-left: 0px;
          }
          button:hover {
            //border: 3px solid #0090f0;
            border: 3px solid $border-active;
            cursor: pointer;
          }
        }
      }

      #twitterBox {
        min-height: 100px;
      }
    }

    #about {
      h4 {
        color: $ricardoworks-blue;
      }
      ul {
        li {
          list-style: none;
          margin-bottom: 5px;
        }
      }
      #bioPic {
        height: 200px;
        display: block;
        margin: auto;
        border: 5px solid #cadee1;
        margin-bottom: 10px;
      }

      #ProficiencyBox {
        ul {
          margin-bottom: 10px;
          display: inline-block;
          li {
            float: left;
            display: block;
          }
        }
      }
      #skillsBox {
        ul {
          li {
            position: relative;
            padding: 0 0 0 20px;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 6px;
              height: 5px;
              width: 5px;
              border: 1px solid $ricardoworks-blue;
              border-width: 2px 2px 0 0;
              transform: rotate(45deg);
            }
          }
        }
      }
      #experienceBox {
        ul {
          li {
            position: relative;
            padding: 0 0 0 20px;
            &:nth-child(even) {
              //background-color: $ricardoworks-lightgrey;
              // color: $ricardoworks-cyan;
            }
            &::before {
              content: "";
              position: absolute;
              left: 2px;
              top: 5px;
              width: 3px;
              height: 6px;
              border: solid $ricardoworks-blue;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
        }
      }
    }
    .outerElement {
      background: url("../assets/images/tileBlack.png");
      color: #fff;
      border-color: #fff;
      border-style: solid;
      border-width: 5px;
      border-radius: 3px;
      margin-bottom: 10px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      padding: 10px;
      max-width: 1024;
    }
  }
}

#loader {
  width: 100%;
  height: 100%;
  img {
    width: 150px;
    height: 150px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.spinner {
  animation: rotate 1.2s linear infinite;
  -webkit-animation: rotate 1.2s linear infinite;
  -moz-animation: rotate 1.2s linear infinite;
}

.nav {
  color: #fff;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  .nav__menu {
    color: #000;
  }
  .nav__control {
    background-color: #fff;
    //border: 3px solid #ff6200;
    border: $border-inactive;
    border-radius: 3px;
    &:hover {
      cursor: pointer;
      //border: 3px solid #0090f0;
      border: $border-active;
      color: #ff6200;
    }
    &:focus-within {
      border: $border-active;
      color: #ff6200;
    }
  }

  .nav__placeholder {
    // color: #0090f0;
    color: #000;
  }

  .nav__indicator {
    //color: #000;
    color: #ff6200;
  }
  .nav__indicator:hover {
    color: $ricardoworks-green;
  }
  .nav__single-value {
    color: #000;
  }
}

.workDescriptionText {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: $ricardoworks-black;
  color: $ricardoworks-white;
  padding: 15px;
  opacity: 0.9;
  h3 {
    text-align: center;
  }
  .workLinkText {
    margin-top: 5px;
  }
}

a {
  text-decoration: none;
  color: $ricardoworks-orange;
  &:hover {
    color: $ricardoworks-green;
  }
}

.devTags {
  //border: 3px solid $ricardoworks-blue;
  background-color: $ricardoworks-blue;
  //background-image: url("./assets/images/lab-beaker-icon.svg");
  padding: 5px;
  color: $ricardoworks-white;
  text-align: center;
  min-width: 90px;
  margin-left: 5px;
  margin-bottom: 5px;
  &:first {
    margin-left: 0px;
  }
}

#dynamicForm {
  height: 150px;
  button[type="button"]#resetButton {
    display: block;
    width: 50px;
    height: 50px;
    margin-top: 15px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  button[type="button"] {
    min-width: 200px;
    height: 50px;
    border: $border-inactive;
    background-color: $ricardoworks-white;
    margin-bottom: 5px;
    transition-property: background-color;
    transition-duration: 0.5s;
    &:hover {
      border: $border-active;
      background-color: $ricardoworks-lightgrey;
      color: $ricardoworks-green;
      cursor: pointer;
    }
    &:focus {
      border: $border-active;
      background-color: $ricardoworks-lightgrey;
      color: $ricardoworks-green;
    }
  }
  ul {
    li {
      list-style: none;
    }
  }
}

#fixedHead {
  background-image: url("../assets/images/tileGrey.png");
  position: fixed;
  z-index: 999;
  width: 100%;
}

a {
  color: $ricardoworks-orange;
  &:hover {
    color: $ricardoworks-green;
    cursor: pointer;
  }
  &:focus {
    color: $ricardoworks-green;
  }
}

/* Animations */

@keyframes border-pulse {
  0% {
    border-color: $border-inactive;
  }
  25% {
    border-color: $ricardoworks-orange2;
  }
  50% {
    border-color: $ricardoworks-orange2;
  }
  100% {
    border-color: $border-inactive;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
