@media (min-width: 600px) {
  #root {
    nav {
      #navBar {
        display: block;
        list-style: none;
        li {
          float: left;
          width: 25%;
          text-align: center;
          background: $ricardoworks-white;
          height: 30px;
          line-height: 25px;
          border-bottom: 1px solid $ricardoworks-darkgrey;
          border-top: 1px solid $ricardoworks-darkgrey;
          border-right: 1px solid $ricardoworks-darkgrey;
          transition-property: background-color;
          transition-duration: 0.5s;
          &:first-of-type {
            border-left: 1px solid $ricardoworks-darkgrey;
          }
          &:hover {
            background-color: $ricardoworks-lightgrey;
          }
          &:focus-within {
            background-color: $ricardoworks-lightgrey;
          }
          button {
            border: none;
            background: none;
            color: $ricardoworks-orange;
            width: 100%;
            height: 100%;
            display: inline-block;
            &:hover {
              color: $ricardoworks-green;
              cursor: pointer;
            }
            &:focus {
              color: $ricardoworks-green;
            }
          }
        }
      }
      #navMenu {
        display: none;
      }
    }
    main#views {
      #workshop {
        #AllWorks {
          margin-right: auto;
          margin-left: auto;
          float: none;
          #pieces {
            .piece {
              float: left;
              margin-left: 10px;
              &:first-of-type {
                margin-left: 0px;
              }

              .pieceCover {
              }
            }
          }
        }
      }
    }
  }
}
